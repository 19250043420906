/**
 * @name: 会员管理-会员余额充值接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 会员管理-会员余额充值接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postF, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IBalanceRes, IBalanceParams, IBalanceRecharge } from "./types";

export const balancePageApi = (params: IBalanceParams) => get<IBalanceRes>('/admin/user/balance/record/query', params)

export const balanceRechargeApi = (data: IBalanceRecharge[]) => postJ('/admin/user/balance/recharge', data)

export const templateDownloadApi = () => postJ('/admin/user/balance/recharge/templateDownload', {}, 'blob')

export const balanceImportApi = (formData: FormData) => postF('/admin/user/balance/recharge/readExcelRechargePrice', formData)

export const balanceExportApi = (id: string) => postJ('/admin/user/balance/recharge/export/errorResult', {id}, 'blob')

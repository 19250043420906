
/**
 * @name: 会员管理-会员余额充值
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 会员管理-会员余额充值
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
balanceImportApi,
  balancePageApi,
  balanceRechargeApi,
  templateDownloadApi,
  balanceExportApi
} from '@/apis/member/balance'
import {
  memberDropApi
} from '@/apis/member/list'
import {
  IMember
} from '@/apis/member/list/types'
import {
  IBalance,
  IBalanceParams,
  IBalanceRecharge
} from '@/apis/member/balance/types'
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class memberBalance extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IBalance[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IBalanceParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IBalance> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    dialogWidth: '600px',
    labelWidth: '120px',
    addTitle: '充值',
    menu: false,
    column: [
      {
        label: '会员',
        prop: 'nickNameOrPhone',
        search: true,
        placeholder: '昵称/手机号',
        hide: true,
        addHide: true
      },
      {
        label: '昵称',
        prop: 'nickName',
        align: 'center',
        addHide: true
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        addHide: true,
        slot: true
      },
      {
        label: '充值金额（元）',
        prop: 'price',
        align: 'center',
        rules: [
          {
            required: true,
            message: '请输入充值金额',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!/^[1-9]\d*$/.test(value)) {
                return callback(new Error('请输入正整数'))
              }
              if (parseInt(value) > 999999) {
                return callback(new Error('最大值不能超过999999'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        label: '充值时间',
        prop: 'addTime',
        search: true,
        type: 'daterange',
        addHide: true,
        align: 'center'
      },
      {
        label: '经办人',
        prop: 'realName',
        addHide: true,
        align: 'center'
      }
    ]
  }

  selectMemberPhone = ''

  selectMembers: IMember[] = []

  memberList: IMember[] = []

  loading = false

  totalPrice = 0

  importDialog = false

  fileList: {id: number, file: File}[] = []

  resultDialog = false

  errObj: any = {}

  openAdd () {
    this.selectMemberPhone = ''
    this.memberList = []
    this.loading = false
    this.selectMembers = []
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }


  remoteMethod (query: string) {
    if (query !== '') {
      this.loading = true;
      memberDropApi({member: query, status: 1}).then(e => {
        this.memberList = e.filter(item => !this.selectMembers.some(i => i.phone === item.phone)).map(item => {
          return {
            ...item,
            cusName: `${item.nickName} ${item.phone}`
          }
        })
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.memberList = [];
    }
  }

  getList () {
    this.tableLoading = true

    const query: IBalanceParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    balancePageApi(query).then(e => {
      this.tableLoading = false
      this.tableData = e.page.list
      this.tableTotal = e.page.total
      this.totalPrice = e.totalPrice
    })
  }

  handleAddMember () {
    if (!this.selectMemberPhone) {
      return this.$message.error('请选择会员')
    }
    const member = this.memberList.find(item => item.phone === this.selectMemberPhone)
    if (!member) {
      return this.$message.error('会员数据异常，请刷新页面重试')
    }
    this.selectMembers.push(member)
    this.selectMemberPhone = ''
    this.memberList = []
    this.loading = false
  }

  handleRemoveMember (index: number) {
    this.selectMembers.splice(index, 1)
  }

  rowSave (form: IBalance, done: Function, loading: Function) {
    if (!this.selectMembers || !this.selectMembers.length) {
      loading()
      return this.$message.error('请选择会员')
    }
    const arr: IBalanceRecharge[] = this.selectMembers.map(item => {
      return {
        phone: item.phone,
        price: form.price
      }
    })
    balanceRechargeApi(arr).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  /**
   * 表格导入批量充值
   */
  openExcelImport () {
    this.importDialog = true
  }

  templateDownload () {
    templateDownloadApi().then(e => {
      exportFile(e, '批量充值模板.xlsx')
    })
  }


  removeFile (index: number) {
    this.$confirm('确定移除该文件？', '提示', {
      type: 'warning'
    }).then(() => {
      this.fileList.splice(index, 1)
    }).catch(() => {})
  }

  fileChange (event: any) {
    const files = event.target.files;
    if (files && files.length) {
      this.fileList.push({
        id: new Date().getTime(),
        file: files[0]
      })
    }
  }

  chooseFile () {
    const dom: any = document.querySelector('#fileInput')
    if (dom) {
      dom.value = ''
      dom.click()
    }
  }

  importEnter (done: Function, loading: Function) {
    if (!this.fileList || !this.fileList.length) {
      loading()
      return this.$message.error('请选择文件')
    }
    const formData = new FormData()
    formData.append('file', this.fileList[0].file)
    balanceImportApi(formData).then(e => {
      if (e) {
        const results: any = e;
        if (results.failures === 0) {
          this.$message.success('操作成功!')
        } else {
          this.errObj = results;
          this.resultDialog = true
        }
        done()
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  exportError () {
    balanceExportApi(this.errObj.id).then(e => {
      exportFile(e, '充值结果.xlsx')
    })
  }

  importClose () {
    this.fileList = []
  }

  created () {
    this.getList()
  }
}
